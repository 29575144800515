import { useSentry } from '~/composables/sentry/useSentry'
// Temporary fix for freeze page after double click bug on links.
// Reproduce bug: double click on link (internal app links in header, issue card etc.) and page will freeze.
// For more info see https://github.com/nuxt/nuxt/issues/13309#issuecomment-1895782875
// Sentry issue: https://cfgtech.sentry.io/issues/4460586572/events/d0f5020bd9fe41288b11818c41efd674/
// TODO: Try to remove after upgrade nuxt and vue js to latest version (> vue@3.4.13)

export default defineNuxtPlugin((app) => {
  let timeout: ReturnType<typeof setTimeout> | null = null

  const route = useRoute()

  app.hook('page:finish', () => {
    restoreInteractivity()

    if (timeout) {
      clearTimeout(timeout)
    }
  })

  document.addEventListener('click', (event) => {
    // Check if a clicked element is a link
    const clickedLinkElement = (event.target as HTMLElement).closest('a')

    // open link in new tab, do not freeze link
    if (event.ctrlKey || event.metaKey) {
      return
    }

    if (clickedLinkElement) {
      // Ignore links with class .cfg-checkbox
      if (clickedLinkElement.classList.contains('cfg-checkbox')) {
        return
      }

      const linkHrefPath = clickedLinkElement?.getAttribute('href')?.split('?')[0]?.split('#')[0]

      const isInternalLink = linkHrefPath?.startsWith('/')
      const isTheSamePage = linkHrefPath === route.path

      const linkTarget = clickedLinkElement?.getAttribute('target')
      const isLinkInSelf = !linkTarget || linkTarget === '_self'

      if (!isInternalLink || isTheSamePage || !isLinkInSelf) {
        return
      }

      lockInteractivity()

      if (timeout) {
        clearTimeout(timeout)
      }

      timeout = setTimeout(async () => {
        restoreInteractivity()

        await useSentry().captureException(
          new Error('Interactive state did not restore after 20 seconds. Restored manually.'),
          { clickedLinkElement },
        )
      }, 20000)
    }
  })
})

function restoreInteractivity() {
  document.documentElement.style.pointerEvents = 'auto'
}

function lockInteractivity() {
  document.documentElement.style.pointerEvents = 'none'
}
