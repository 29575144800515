import { browserTracingIntegration } from '@sentry/vue'
import { defineNuxtPlugin, useRuntimeConfig } from '#app'

declare const APP_NAME: string
declare const APP_VERSION: string

export default defineNuxtPlugin({
  name: 'Sentry',
  parallel: true,

  async setup({ vueApp }) {
    const Sentry = (await import('@sentry/vue'))

    const config = useRuntimeConfig()

    if (!config.app.SENTRY_DSN) {
      console.warn('[Sentry]: Missing DSN config.')
      return
    }

    const router = useRouter()

    Sentry.init({
      app: vueApp,
      dsn: config.app.SENTRY_DSN,
      environment: config.app.ENVIRONMENT,
      release: `${APP_NAME}@${APP_VERSION}`,
      integrations: [
        browserTracingIntegration({
          router,
        }),
      ],
      tracesSampleRate: 0.2,
      tracePropagationTargets: ['localhost', 'srovnavacdluhopisu.cz', 'https://dev-srov.cfgtech.io'],
      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
    })
  },
})
