import { defineNuxtPlugin } from '#app/nuxt'
import { LazySBAdBanner, LazySBAnchor, LazySBBenefitItem, LazySBBenefits, LazySBBestIssues, LazySBBlogCard, LazySBBlogPosts, LazySBBreadCrumbs, LazySBButton, LazySBButtonGroup, LazySBCalculator, LazySBContactForm, LazySBContactInfo, LazySBContainer, LazySBContentCard, LazySBContentPage, LazySBContentPageContent, LazySBContentPageHeader, LazySBExtendedProfileCardItem, LazySBFaq, LazySBFlex, LazySBGrid, LazySBGridColumn, LazySBHeadline, LazySBHeroSection, LazySBImage, LazySBIssuerConsulting, LazySBLandingPage, LazySBPricing, LazySBProfileCard, LazySBProfileCardItem, LazySBReview, LazySBSection, LazySBSeo, LazySBSpacer, LazySBStatisticsCard, LazySBStepper, LazySBStepperItem, LazySBTextContainer, LazySBTextWithNumber, LazySBVerticalBenefitItem, LazySBVerticalBenefits, LazySBVideo, LazySbSignUp, LazyHelpers } from '#components'
const lazyGlobalComponents = [
  ["SBAdBanner", LazySBAdBanner],
["SBAnchor", LazySBAnchor],
["SBBenefitItem", LazySBBenefitItem],
["SBBenefits", LazySBBenefits],
["SBBestIssues", LazySBBestIssues],
["SBBlogCard", LazySBBlogCard],
["SBBlogPosts", LazySBBlogPosts],
["SBBreadCrumbs", LazySBBreadCrumbs],
["SBButton", LazySBButton],
["SBButtonGroup", LazySBButtonGroup],
["SBCalculator", LazySBCalculator],
["SBContactForm", LazySBContactForm],
["SBContactInfo", LazySBContactInfo],
["SBContainer", LazySBContainer],
["SBContentCard", LazySBContentCard],
["SBContentPage", LazySBContentPage],
["SBContentPageContent", LazySBContentPageContent],
["SBContentPageHeader", LazySBContentPageHeader],
["SBExtendedProfileCardItem", LazySBExtendedProfileCardItem],
["SBFaq", LazySBFaq],
["SBFlex", LazySBFlex],
["SBGrid", LazySBGrid],
["SBGridColumn", LazySBGridColumn],
["SBHeadline", LazySBHeadline],
["SBHeroSection", LazySBHeroSection],
["SBImage", LazySBImage],
["SBIssuerConsulting", LazySBIssuerConsulting],
["SBLandingPage", LazySBLandingPage],
["SBPricing", LazySBPricing],
["SBProfileCard", LazySBProfileCard],
["SBProfileCardItem", LazySBProfileCardItem],
["SBReview", LazySBReview],
["SBSection", LazySBSection],
["SBSeo", LazySBSeo],
["SBSpacer", LazySBSpacer],
["SBStatisticsCard", LazySBStatisticsCard],
["SBStepper", LazySBStepper],
["SBStepperItem", LazySBStepperItem],
["SBTextContainer", LazySBTextContainer],
["SBTextWithNumber", LazySBTextWithNumber],
["SBVerticalBenefitItem", LazySBVerticalBenefitItem],
["SBVerticalBenefits", LazySBVerticalBenefits],
["SBVideo", LazySBVideo],
["SbSignUp", LazySbSignUp],
["Helpers", LazyHelpers],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
