export const paths = {
  home: '/',
  companies: '/emitenti',
  companyDetail: '/emitenti/dluhopisy-:slug',
  issues: '/dluhopisy',
  issuesBanner: '/dluhopisy/banner',
  corporateIssues: '/dluhopisy/firemni',
  stateIssues: '/dluhopisy/statni',
  issueDetail: '/dluhopisy/:slug',
  blog: '/blog',
  blogPost: '/blog/:slug',
} as const
