import { default as _91_46_46_46slug_93IdVsJ0OBGJMeta } from "/home/runner/work/srov-app/srov-app/client/pages/[...slug].vue?macro=true";
import { default as _91slug_93UwEoYBArn4Meta } from "/home/runner/work/srov-app/srov-app/client/pages/blog/[slug].vue?macro=true";
import { default as indexDvwW8RssjnMeta } from "/home/runner/work/srov-app/srov-app/client/pages/blog/index.vue?macro=true";
import { default as _91slug_93vDfydVqwWpMeta } from "/home/runner/work/srov-app/srov-app/client/pages/companies/[slug].vue?macro=true";
import { default as indexjwD248GfxvMeta } from "/home/runner/work/srov-app/srov-app/client/pages/companies/index.vue?macro=true";
import { default as _91_46_46_46filters_93Yc5sPMP9Q3Meta } from "/home/runner/work/srov-app/srov-app/client/pages/issues/[category]/[...filters].vue?macro=true";
import { default as _91slug_93oT0j6fV3EsMeta } from "/home/runner/work/srov-app/srov-app/client/pages/issues/[slug].vue?macro=true";
import { default as bannerTgg5nw59SuMeta } from "/home/runner/work/srov-app/srov-app/client/pages/issues/banner.vue?macro=true";
import { default as component_45stubmQZyiQedgSMeta } from "/home/runner/work/srov-app/srov-app/client/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubmQZyiQedgS } from "/home/runner/work/srov-app/srov-app/client/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___cs",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93IdVsJ0OBGJMeta || {},
    component: () => import("/home/runner/work/srov-app/srov-app/client/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___cs",
    path: "/blog/:slug()",
    component: () => import("/home/runner/work/srov-app/srov-app/client/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog___cs",
    path: "/blog",
    meta: indexDvwW8RssjnMeta || {},
    component: () => import("/home/runner/work/srov-app/srov-app/client/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "companies-slug___cs",
    path: _91slug_93vDfydVqwWpMeta?.path ?? "/companies/:slug()",
    component: () => import("/home/runner/work/srov-app/srov-app/client/pages/companies/[slug].vue").then(m => m.default || m)
  },
  {
    name: "companies___cs",
    path: indexjwD248GfxvMeta?.path ?? "/companies",
    component: () => import("/home/runner/work/srov-app/srov-app/client/pages/companies/index.vue").then(m => m.default || m)
  },
  {
    name: "issues-category-filters___cs",
    path: _91_46_46_46filters_93Yc5sPMP9Q3Meta?.path ?? "/issues/:category()/:filters(.*)*",
    component: () => import("/home/runner/work/srov-app/srov-app/client/pages/issues/[category]/[...filters].vue").then(m => m.default || m)
  },
  {
    name: "issues-slug___cs",
    path: _91slug_93oT0j6fV3EsMeta?.path ?? "/issues/:slug()",
    component: () => import("/home/runner/work/srov-app/srov-app/client/pages/issues/[slug].vue").then(m => m.default || m)
  },
  {
    name: "issues-banner___cs",
    path: bannerTgg5nw59SuMeta?.path ?? "/issues/banner",
    meta: bannerTgg5nw59SuMeta || {},
    component: () => import("/home/runner/work/srov-app/srov-app/client/pages/issues/banner.vue").then(m => m.default || m)
  },
  {
    name: component_45stubmQZyiQedgSMeta?.name,
    path: "/issues/:pathMatch(.*)",
    component: component_45stubmQZyiQedgS
  },
  {
    name: component_45stubmQZyiQedgSMeta?.name,
    path: "/kategorie/:pathMatch(.*)",
    component: component_45stubmQZyiQedgS
  },
  {
    name: component_45stubmQZyiQedgSMeta?.name,
    path: "/investorska-sekce",
    component: component_45stubmQZyiQedgS
  }
]