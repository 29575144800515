import type { Popup, PopupOptions } from '@typeform/embed'
import '@typeform/embed/build/css/popup.css'

const localStorageKey = 'dmatOpenedIssueDetails'

export default defineNuxtPlugin({
  name: 'typeform',
  parallel: true,
  async setup() {
    const config = useRuntimeConfig()

    const isActive = config.app.ENVIRONMENT === 'production'

    const [{ createPopup }] = await Promise.all([
      import('@typeform/embed'),
      import('@typeform/embed/build/css/popup.css'),
    ])

    const visitedIssuePages = useLocalStorage<string[]>(localStorageKey, [], {
      onError: () => {
        localStorage.removeItem(localStorageKey)
      },
      writeDefaults: isActive,
    })

    const popup = ref<null | Popup>(null)

    function openTypeformPopup(url: string) {
      const popupSettings: PopupOptions = {
        height: '500',
        width: '600',
        hidden: {
          path: url,
        },
      }

      popup.value = createPopup('AyRcHxyB', {
        ...popupSettings,
      })

      popup?.value.open()
    }

    function logTypeformPageVisit(isin: string) {
      if (!isActive) {
        return
      }

      try {
        const visitedPagesLengthBeforeAddition = visitedIssuePages.value.length // To prevent opening popup again on page reload

        if (visitedPagesLengthBeforeAddition > 1) {
          return
        }

        visitedIssuePages.value = [...new Set([...visitedIssuePages.value, isin])] // Ensure unique values in array

        if (visitedIssuePages.value.length === 2 && visitedPagesLengthBeforeAddition !== visitedIssuePages.value.length) {
          openTypeformPopup(window.location.href)
        }
      }
      catch (error) {
        if (error instanceof TypeError) {
          visitedIssuePages.value = [isin]
        }
      }
    }

    return {
      provide: {
        logTypeformPageVisit,
      },
    }
  },
})
