<script lang="ts" setup>
import { CfgApplicationError } from '@cfgtech/ui'
import type { NuxtError } from '#app'

const props = defineProps<{ error: NuxtError }>()

const { t } = useI18n()

defineRouteRules({
  robots: false,
})

useSeoMeta({
  title: t('common.notFound.pageTitle'),
})

const handleError = () => clearError({ redirect: '/' })
</script>

<template>
  <NuxtErrorBoundary>
    <div class="container flex min-h-screen flex-col py-10">
      <pre v-if="!$t">{{ JSON.stringify(props.error, null, 2) }}</pre>

      <CfgApplicationError
        v-else
        :button-label="$t('common.notFound.buttonLabel')"
        :description="$t('common.notFound.description')"
        :header="$t('common.motto')"
        :status-code="error?.statusCode || ''"
        :title="$t('common.notFound.title')"
        @error="handleError"
      >
        <template #logo>
          <AppLogo @click.prevent="handleError" />
        </template>
      </CfgApplicationError>
    </div>
  </NuxtErrorBoundary>
</template>

<style lang="scss">
.page-not-found__title {
  b {
    @apply text-brand;
  }
}
</style>
