import dayjs from 'dayjs'
import 'dayjs/locale/cs'
import updateLocale from 'dayjs/plugin/updateLocale'

export default defineNuxtPlugin(() => {
  dayjs.extend(updateLocale)

  const localeList = dayjs.Ls

  dayjs.locale('cs')
  dayjs.updateLocale('cs', { relativeTime: { ...localeList?.cs?.relativeTime || [], y: '1 rok' } })
})
