export function useSentry() {
  return {
    async captureException<T>(exception: T, captureContext?: Record<string, unknown>) {
      (await import(process.server ? '@sentry/node' : '@sentry/browser')).captureException(
        exception,
        {
          contexts: { context: captureContext },
        },
      )
    },
  }
}
