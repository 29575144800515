export default defineNuxtPlugin(() => {
  // Description: https://lightrun.com/answers/nuxt-framework-error-when-fast-navigating-failed-to-execute-insertbefore-on-node
  const messages = [
    'Uncaught NotFoundError: Failed to execute \'insertBefore\' on \'Node\': The node before which the new node is to be inserted is not a child of this node.', // chromium based
    'NotFoundError: The object can not be found here.', // safari
  ]

  window.addEventListener('error', (ev) => {
    if (messages.includes(ev.message)) {
      ev.preventDefault()
      window.location.reload()
    }
  })
})
