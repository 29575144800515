// import { defineNuxtPlugin } from '#app';
import { SMARTSUPP_URL } from '~/constants/common'

export default defineNuxtPlugin({
  parallel: true,
  async setup(app) {
    const key = app.$config.app.SMARTSUPP_API_KEY

    const isSmartsuppLoaded = key ? await loadSmartsupp(key) : false

    function openSmartsuppDialog() {
      (window as any)?.smartsupp?.('chat:open')
      addSmartsuppDialogEvent()
    }

    return {
      provide: {
        isSmartsuppLoaded,
        openSmartsuppDialog,
      },
    }
  },
})

function loadSmartsupp(key: string) {
  return new Promise<boolean>((resolve) => {
    // Set smartsupp config <script> tag
    const configScript = document.createElement('script')
    configScript.innerHTML = `var _smartsupp = {}; _smartsupp.key = '${key}'; _smartsupp.hideWidget = true;`
    document.body.appendChild(configScript)

    // Load smartsupp script
    const script = document.createElement('script')
    script.src = SMARTSUPP_URL
    script.defer = true

    function handleLoadScript() {
      addSmartsuppDialogEvent()
      nextTick(() => {
        resolve(true)
      })

      script.removeEventListener('load', handleLoadScript)
    }

    function handleErrorScript() {
      resolve(false)
      script.removeEventListener('error', handleErrorScript)
    }

    script.addEventListener('load', handleLoadScript)
    script.addEventListener('error', handleErrorScript)

    document.body.appendChild(script)
  })
};

function addSmartsuppDialogEvent() {
  (window as any)?.smartsupp?.('on', 'messenger_close', () => {
    (window as any)?.smartsupp?.('chat:hide')
  })
}
