<template>
  <div class="app-logo block">
    <img
      alt="Srovnávač dluhopisů"
      class="h-11 w-full cursor-pointer object-contain object-left"
      loading="lazy"
      src="/srovnavac.svg"
    >
  </div>
</template>

<style lang="scss">
.app-logo {
  &:not([class*="w-"]) {
    @apply w-full max-w-[10rem] xl:max-w-[9rem];
  }
}
</style>
