import { useRuntimeConfig } from '#app'
import { useSentry } from '~/composables/sentry/useSentry'

export default defineNuxtPlugin({
  parallel: true,
  async setup() {
    const { app } = useRuntimeConfig()

    const path = app.ENVIRONMENT === 'local' ? '/sprite.svg' : '/public/sprite.svg'
    const sprite = await useFetch<Blob>(path, {
      retry: 5,
      retryDelay: 500,
      async onRequestError(context) {
        if (!context.options.retry) {
          await useSentry().captureException(
            context.error || new Error('Unable to fetch svg sprite. Error: unknown'),
            { context },
          )
        }
      },
      async onResponseError(context) {
        if (!context.options.retry) {
          await useSentry().captureException(
            context.error || new Error('Unable to fetch svg sprite. Error: unknown'),
            { context },
          )
        }
      },
    })

    if (sprite.data.value) {
      const reader = new FileReader()

      reader.readAsText(sprite.data.value)

      reader.onload = (e) => {
        const el = document.createElement('div')
        el.classList.add('sprite-container')
        el.innerHTML = (e.target as any).result as string
        document.body.appendChild(el)
      }

      reader.onerror = async (event) => {
        await useSentry().captureException(
          new Error('Unable to read svg sprite'),
          { event: event as any },
        )
      }
    }
  },
})
