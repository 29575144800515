import type { AssetStoryblok, MultilinkStoryblok } from '~/types/storyblok'
import type { Media } from '~/api/types/generated/Media'

export function getSBLink(link?: MultilinkStoryblok) {
  if (!link) {
    return ''
  }

  if (link.story) {
    return `${link.story.url}${link.anchor ? `#${link.anchor}` : ''}`
  }

  let linkBase = ''
  if (link.url) {
    linkBase = link.url
  }
  else if (link.cached_url) {
    linkBase = `/${link.cached_url}`
  }
  return `${linkBase}${link.anchor ? `#${link.anchor}` : ''}`
}

export function convertAssetToStrapiMedia(asset: AssetStoryblok): Media {
  const mediaFormat = {
    name: asset.name,
    hash: asset.filename,
    ext: asset.filename.split('.').pop() || '',
    mime: '', // cannot get
    width: asset.width,
    height: asset.height,
    size: 0, // cannot get
    path: asset.filename,
    url: asset.filename,
  }

  return {
    id: asset.id,
    attributes: {
      ...mediaFormat,
      alternativeText: asset.alt || '',
      caption: asset.title || '',
      createdAt: new Date(asset.created_at),
      previewUrl: asset.filename,
      provider: 'storyblok',
      updatedAt: new Date(asset.updated_at),

      formats: {
        thumbnail: mediaFormat,
        medium: mediaFormat,
        small: mediaFormat,
      },
    },
  }
}

/**
 * Returns the size of an image based on its file name.
 * @param imageFileName - The filename property of the Storyblok image asset.
 * @returns The size of the image as an array of numbers [width, height].
 */
export function getSBImageSize(imageFileName: string): [number, number] {
  return (imageFileName?.split('/')?.[5]?.split?.('x').map(Number) as [number, number]) || [0, 0]
}
