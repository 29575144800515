import { TAX } from '~/constants/common'

export const isEven = (index: number): boolean => index % 2 === 0

export function transformMultiselectValue(value: string): string[] {
  try {
    return JSON.parse(value) || []
  }
  catch (_) {
    return []
  }
}

export function addScriptOnce(id: string, code: string): void {
  if (process.client) {
    if (document.getElementById(id)) {
      return
    }

    const script = document.createElement('script')

    script.id = id
    script.async = true

    script.appendChild(document.createTextNode(code))

    document.body.appendChild(script)
  }
}

/**
 * Slice and add elipces when sentence has too long non-breakable word.
 */
export function hasTooLongWord(sentence: string, maxWordLength: number): boolean {
  return sentence.split(' ').some(word => word.length > maxWordLength)
}

/**
 * Check if url has http or https prefix and add https if it doesn't have
 * @returns string with https prefix
 */
export function maybeUrl(url: string): string {
  return url.startsWith('http') ? url : `https://${url}`
}

/**
 * Remove value from object if it's equal to other object value
 */
export function removeEqualValues<T extends object>(obj: T, otherObj: T) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => !isEqual(value, otherObj[key as keyof T])),
  ) as T
}

/**
 * @returns true if value is number or number like string
 */
export function isNumberLike(value: string): boolean {
  return value !== '' && !Number.isNaN(Number(value))
}

/**
 * @param {number} value value without tax
 * @returns value with tax
 */
export function withTax(value: number): number {
  return value - Math.floor(value * TAX)
}

// Todo move to lib and make tests
export function roundToNearest(num: number, { min, max, step }: { min: number, max: number, step?: number }): number {
  const roundedNum = step ? Math.floor(num / step) * step : num

  if (roundedNum < min) {
    return min
  }
  else if (roundedNum > max) {
    return max
  }

  return roundedNum
}

export function diffMonths(startDate: Date, endDate: Date): number {
  return (endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth() - startDate.getMonth()
}
