import revive_payload_client_4sVQNw7RlN from "/home/runner/work/srov-app/srov-app/client/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/srov-app/srov-app/client/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/srov-app/srov-app/client/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import nuxt_plugin_RZGiRwB5P6 from "/home/runner/work/srov-app/srov-app/client/node_modules/nuxt-open-fetch/dist/runtime/nuxt-plugin.mjs";
import _0_siteConfig_MwZUzHrRNP from "/home/runner/work/srov-app/srov-app/client/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/runner/work/srov-app/srov-app/client/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/srov-app/srov-app/client/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/srov-app/srov-app/client/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/srov-app/srov-app/client/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/srov-app/srov-app/client/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import _64nuxt_scripts_D34jXE4xNh from "/home/runner/work/srov-app/srov-app/client/.nuxt/modules/@nuxt-scripts.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/srov-app/srov-app/client/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/srov-app/srov-app/client/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tLmAP220nC from "/home/runner/work/srov-app/srov-app/client/node_modules/@nuxt/image/dist/runtime/plugin.mjs";
import plugin_KlVwwuJRCL from "/home/runner/work/srov-app/srov-app/client/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/home/runner/work/srov-app/srov-app/client/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import strapi_CICqBBANNd from "/home/runner/work/srov-app/srov-app/client/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.mjs";
import amplify_k7zpSlaowH from "/home/runner/work/srov-app/srov-app/client/node_modules/@cfgtech/srov-shared/plugins/amplify.ts";
import current_version_client_xdxKKs8d8Y from "/home/runner/work/srov-app/srov-app/client/node_modules/@cfgtech/srov-shared/plugins/current-version.client.ts";
import dayjs_pGiXRjcsJO from "/home/runner/work/srov-app/srov-app/client/plugins/dayjs.ts";
import link_freeze_client_T58kmbsrJ6 from "/home/runner/work/srov-app/srov-app/client/plugins/link-freeze.client.ts";
import patchError_client_uWqyqALve4 from "/home/runner/work/srov-app/srov-app/client/plugins/patchError.client.ts";
import scripts_client_xHWkRR3iR6 from "/home/runner/work/srov-app/srov-app/client/plugins/scripts.client.ts";
import scroll_to_hash_CTCniV1Z3w from "/home/runner/work/srov-app/srov-app/client/plugins/scroll-to-hash.ts";
import sentry_client_shVUlIjFLk from "/home/runner/work/srov-app/srov-app/client/plugins/sentry.client.ts";
import smartsupp_client_HUImH5w65Z from "/home/runner/work/srov-app/srov-app/client/plugins/smartsupp.client.ts";
import storyblokPreview_DYPwibJYs7 from "/home/runner/work/srov-app/srov-app/client/plugins/storyblokPreview.ts";
import svgSprite_client_NXGsy3rR9P from "/home/runner/work/srov-app/srov-app/client/plugins/svgSprite.client.ts";
import typeform_client_WVOoYpVmSA from "/home/runner/work/srov-app/srov-app/client/plugins/typeform.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  nuxt_plugin_RZGiRwB5P6,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  _64nuxt_scripts_D34jXE4xNh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tLmAP220nC,
  plugin_KlVwwuJRCL,
  i18n_yfWm7jX06p,
  strapi_CICqBBANNd,
  amplify_k7zpSlaowH,
  current_version_client_xdxKKs8d8Y,
  dayjs_pGiXRjcsJO,
  link_freeze_client_T58kmbsrJ6,
  patchError_client_uWqyqALve4,
  scripts_client_xHWkRR3iR6,
  scroll_to_hash_CTCniV1Z3w,
  sentry_client_shVUlIjFLk,
  smartsupp_client_HUImH5w65Z,
  storyblokPreview_DYPwibJYs7,
  svgSprite_client_NXGsy3rR9P,
  typeform_client_WVOoYpVmSA
]