export const APP_DATE_FORMAT = 'D. M. YYYY'
export const TAX = 0.15

export const SUPPORT_URL = 'https://srovnavacdluhopisu.cz/zmena-zapisu'
export const OPERATING_PRINCIPLES = 'https://srovnavacdluhopisu.cz/principy-fungovani/'
export const ISSUER_SECTION_URL = 'https://emitent.srovnavacdluhopisu.cz/auth/register'
export const INFO_EMAIL = 'info@srovnavacdluhopisu.cz'

export const HEADER_HEIGHT_DESKTOP = 90
export const HEADER_HEIGHT_DESKTOP_WITH_ISSUE_NAVIGATION = HEADER_HEIGHT_DESKTOP + 55

export const BOND_AMOUNT = 10

export const SMARTSUPP_URL = 'https://www.smartsuppchat.com/loader.js'
